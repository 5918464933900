import React from 'react';
import "./navBar.css";
import {
  Collapse,
  Navbar, 
  Nav,
  NavItem,
  NavLink,
  } from 'reactstrap';

export class NavBar extends React.Component {  

  render() {
    return (
      <div>
        <Navbar color="" light expand="md">
          {/* <NavbarToggler /> */}
          <Collapse navbar>
            {/* <img className="house" src={house_white_filled_LOGO}></img> */}
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink className='word' href="/hem">Hem</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='word' href="mailto:info@lavafastigheter.se">Kontakt</NavLink>
                </NavItem>
                 <NavItem>
                    <NavLink className='word' href="mailto:felanmalan@lavafastigheter.se">Felanmälan</NavLink>
                </NavItem>
                 <NavItem>
                    <NavLink href="/intresse">Intresseanmälan</NavLink>
                </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        
      </div>
    );
  }
}

export default NavBar;