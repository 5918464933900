import React from "react";
import './intresse.css';


export class Intresse extends React.Component {
    render() {
       return (
         <div className="">
           <p className="regler">Saker att tänka på för dig som vill hyra lägenhet:</p>
             <ul className="lista">
               <li>Du ska ha fyllt 18 år.</li>
               <li>Du får inte ha några betalningsanmärkningar.</li>
               <li>Du har en stadigvarande inkomst som inte understiger <br/>tre gånger årshyran  för den aktuella lägenheten före skatt.</li>
               <li>Vi tar alltid en kreditupplysning på dig.</li>
               <li>Som regel tar vi referenser från tidigare hyresvärd.</li>
               <li>Registrering och intresseanmälan.</li>
               <li>Intresseanmälan gäller ett år från inskickat datum.</li>           
            </ul>  

            <a className="intressemail" href="https://intresseanmalan.dinafastigheter.se?b=20000">Klicka här för att komma vidare till anmälningsformuläret</a>


        </div>
    );
    
    }
  }
  
  export default Intresse;
               
               
               
            